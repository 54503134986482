import React, { useState, useEffect, ChangeEvent } from "react";
import { ChatMessage } from "../Entity/ChatMessage";
import ENDPOINTS from "../config"; // Importamos la configuración centralizada

interface HistoryComponentProps {
  onHistoryClick: (messages: ChatMessage[], historyId: number) => void;
}

const HistoryComponent: React.FC<HistoryComponentProps> = ({
  onHistoryClick,
}) => {
  const [chatHistories, setChatHistories] = useState<any[]>([]);
  const [filteredHistories, setFilteredHistories] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const updateHeight = () => {
      const newHeight = window.innerHeight - window.innerHeight / 2 - 15;
      setHeight(newHeight);
    };

    // Llamar a la función inicialmente y en cada cambio de tamaño de la ventana
    updateHeight();
    window.addEventListener("resize", updateHeight);
    fetchChatHistories();
    // Limpieza del event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    const filtered = chatHistories.filter((history) =>
      history.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredHistories(filtered);
  }, [searchTerm, chatHistories]);

  const fetchChatHistories = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(ENDPOINTS.CHAT_HISTORY(0, 100), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Chat histories:", data);
      setChatHistories(data); // No need to append to previous state here
    } catch (error) {
      console.error("Error fetching chat histories:", error);
    } finally {
      console.log("Chat histories loaded");
    }
  };

  const handleClickHistory = async (historyId: number) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(ENDPOINTS.CHAT_MESSAGES(historyId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      onHistoryClick(data, historyId);
      console.log("Chat messages:", data);
      console.log("History ID:", historyId);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="flex flex-col h-full overflow-hidden border-l border-slate-300 dark:border-slate-700 bg-slate-50 dark:bg-slate-900">
      <div className="flex-shrink-0 bg-white dark:bg-slate-900  ">
        <h1 className="text-xl px-4 py-2 font-bold text-slate-800 dark:text-slate-200">
          Chat History
        </h1>
        <form className="px-4 py-2">
          <label htmlFor="search-chats" className="sr-only">
            Search chats
          </label>
          <div className="relative">
            <input
              id="search-chats"
              type="text"
              className="w-full rounded-lg border border-slate-300 bg-slate-50 p-3 pr-10 text-sm text-slate-800 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:border-slate-700 dark:bg-slate-900 dark:text-slate-200"
              placeholder="Search chats"
              value={searchTerm}
              onChange={handleSearchChange}
              required
            />
            <button
              type="button"
              className="absolute bottom-2 right-2.5 rounded-lg p-2 text-sm text-slate-500 hover:text-blue-700 focus:outline-none sm:text-base"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                aria-hidden="true"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 9h8"></path>
                <path d="M8 13h5"></path>
                <path d="M11.008 19.195l-3.008 1.805v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5"></path>
                <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                <path d="M20.2 20.2l1.8 1.8"></path>
              </svg>
              <span className="sr-only">Search chats</span>
            </button>
          </div>
        </form>
      </div>
      <div
        className="flex-grow overflow-y-auto p-4 space-y-4"
        style={{ height: `calc(${height}px - 4rem)` }} // Ajustar la altura considerando el encabezado y la búsqueda
      >
        {filteredHistories.map((history, index) => (
          <button
            key={index}
            className="flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-blue-500 hover:text-white focus:outline-none dark:hover:bg-blue-600"
            onClick={() => handleClickHistory(history.id)}
          >
            <h1 className="text-sm font-medium capitalize text-slate-700 transition-colors duration-200 group-hover:text-blue-600 dark:text-slate-200 dark:group-hover:text-white">
              {history.name}
            </h1>
            <p className="text-xs text-slate-500 transition-colors duration-200 group-hover:text-blue-300 dark:text-slate-400 dark:group-hover:text-slate-300">
              {new Date(history.create_date).toLocaleDateString()}
            </p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default HistoryComponent;

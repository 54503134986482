import React, { useEffect, useRef, useState } from "react";
import PromptTemplateDialog from "./PromptComponets/PromptTemplateDialog";
import { PromptTemplate } from "../Entity/PromptTemplate";
import HistoryComponent from "./HistoryComponent";
import { ChatMessage } from "../Entity/ChatMessage";
import PromptListComponent from "./PromptComponets/PromptListComponent";
import ENDPOINTS from "../config";
import UserSettingsModal from "./UserSettingsModal";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

interface PromptSideTemplateDialogProps {
  onSave: (template: PromptTemplate) => void;
  updateMessages: (messages: ChatMessage[]) => void;
  setHistoryId: React.Dispatch<React.SetStateAction<number | null>>; // Añadir la prop para setHistoryId
  updatePromptTemplates: () => Promise<void>;
  promptTemplates: PromptTemplate[];
}

const SidebarComponent: React.FC<PromptSideTemplateDialogProps> = ({
  onSave,
  updateMessages,
  setHistoryId,
  updatePromptTemplates,
  promptTemplates,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isHistoryVisible, setIsHistoryVisible] = useState(true);
  const [isPromptListVisible, setIsPromptListVisible] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState<PromptTemplate | null>(
    null
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const { t } = useTranslation();
  const logoUrl = process.env.REACT_APP_LOGO_URL || "";
  const nameOfCompany = process.env.REACT_APP_COMPANY_NAME || "";
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // toggle the sidebar state
  };

  const handleClickOutside = (event: MouseEvent) => {
    // Check if the sidebar is open and the click is outside the sidebar
    if (
      isSidebarOpen &&
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      // Close the sidebar only on small screens
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      }
    }
  };

  useEffect(() => {
    // Add event listener to document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(true); // Open sidebar for desktop view
      } else {
        setIsSidebarOpen(false); // Close sidebar for mobile view
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on mount to set initial state
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleHistoryVisibility = () => {
    setIsHistoryVisible(true); // Mostrar el historial
    setIsPromptListVisible(false); // Ocultar la lista de prompts
    // Mantener el sidebar abierto en pantallas pequeñas
    if (window.innerWidth < 768) {
      setIsSidebarOpen(true);
    }
  };

  const togglePromptListVisibility = () => {
    setIsPromptListVisible(true);
    setIsHistoryVisible(false); // Ocultar el historial si se muestra la lista de prompts
  };

  const handlePromptClick = (template: PromptTemplate) => {
    setSelectedPrompt(template);
    setIsDialogOpen(true);
  };

  const handleHistoryClick = (messages: ChatMessage[], historyId: number) => {
    updateMessages([]);
    updateMessages(messages);
    setHistoryId(historyId);
    console.log("History clicked", messages, historyId);
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
    }
  };

  const handleNewPromptClick = () => {
    setSelectedPrompt(null);
    setIsDialogOpen(true);
  };

  const handleNewChatClick = () => {
    setHistoryId(null);
    updateMessages([]);

    // Cerrar el sidebar solo en pantallas pequeñas
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
    }
  };

  const handleSave = async (template: PromptTemplate) => {
    if (selectedPrompt && selectedPrompt.id) {
      // Update existing prompt template
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          ENDPOINTS.PROMPT_TEMPLATE(selectedPrompt.id),
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(template),
          }
        );

        if (response.ok) {
          console.log("Prompt template updated successfully");
          updatePromptTemplates();
        } else {
          console.error("Failed to update prompt template");
        }
      } catch (error) {
        console.error("Error updating prompt template:", error);
      }
    } else {
      // Logic to create a new prompt template can go here
      onSave(template);
    }
    setIsDialogOpen(false);
    setSelectedPrompt(null);
  };

  const handleLogout = () => {
    // Lógica para realizar el logout aquí
    localStorage.removeItem("token"); // Elimina el token del localStorage u otro almacenamiento
    window.location.href = "/login"; // Redirige a la página de inicio de sesión
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language).then(() => {
      console.log("Selected language:", language);
      setIsPopupOpen(false);
    });
  };

  return (
    <div className="flex">
      {/* Botón para abrir/cerrar el sidebar */}
      <div
        className={`fixed z-50 w-72 flex items-center space-x-4 left-0 top-0 px-4 py-2 transition-all duration-300 ${isSidebarOpen
          ? "bg-white dark:bg-slate-900"
          : "bg-transparent dark:bg-transparent"
          }`}
      >
        <img src={logoUrl} alt="logo" className="h-10 w-10" />
        <div
          className={`flex items-center space-x-2 ${isSidebarOpen ? "block" : "hidden"
            }`}
        >
          <h2 className="text-lm  text-slate-800 dark:text-slate-200">
            {nameOfCompany}
          </h2>
        </div>

        <div>
          {/* Botón hamburguesa - Solo visible en móviles */}
          <div
            className={`md:hidden ${isSidebarOpen ? "" : "is-sidebar-open"}`}
          >
            <button
              className="p-2 hover:bg-gray-600 text-white rounded-full transition-all duration-300"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="icon-xl-heavy"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M4 6h16a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2zm0 4h16a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2zm0 4h16a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        {/* Botón para abrir y cerrar la sidebar - Solo visible en escritorio */}
        <div className="hidden md:block">
          <button
            className="p-2 hover:bg-gray-600 text-white rounded-full transition-all duration-300"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            {isSidebarOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="icon-xl-heavy"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="icon-xl-heavy"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M8.857 3h6.286c1.084 0 1.958 0 2.666.058.729.06 1.369.185 1.961.487a5 5 0 0 1 2.185 2.185c.302.592.428 1.233.487 1.961.058.708.058 1.582.058 2.666v3.286c0 1.084 0 1.958-.058 2.666-.06.729-.185 1.369-.487 1.961a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C17.1 21 16.227 21 15.143 21H8.857c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.232-.487-1.961C1.5 15.6 1.5 14.727 1.5 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 4.23 3.544c.592-.302 1.233-.428 1.961-.487C6.9 3 7.773 3 8.857 3M6.354 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216-.05.617-.051 1.41-.051 2.546v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h.6V5h-.6c-1.137 0-1.929 0-2.546.051M11.5 5v14h3.6c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546v-3.2c0-1.137 0-1.929-.051-2.546-.05-.605-.142-.953-.276-1.216a3 3 0 0 0-1.311-1.311c-.263-.134-.611-.226-1.216-.276C17.029 5.001 16.236 5 15.1 5zM5 8.5a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1M5 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>

      <aside
        ref={sidebarRef}
        className={`flex flex-col bg-slate-50 pt-8 dark:border-slate-700 dark:bg-slate-900 duration-100 text-gray-100 px-0 ${isSidebarOpen
          ? "w-72 h-screen overflow-y-auto"
          : "w-0 overflow-hidden"
          }`}
      >
        <div
          className={`flex flex-col ${isSidebarOpen ? "block" : "hidden"
            } md:block`}
        >
          {isDialogOpen && (
            <PromptTemplateDialog
              onSave={handleSave}
              onClose={() => setIsDialogOpen(false)}
              iName={selectedPrompt ? selectedPrompt.name : ""}
              iDescription={selectedPrompt ? selectedPrompt.description : ""}
              iContent={selectedPrompt ? selectedPrompt.content : ""}
            />
          )}
          <UserSettingsModal
            isOpen={isSettingsModalOpen}
            onClose={() => setIsSettingsModalOpen(false)}
            onLanguageChange={handleLanguageChange}
          />

          <button
            id="toggle-sidebar"
            onClick={handleNewChatClick}
            className="mt-10 ml-5 flex w-60 gap-x-4 rounded-lg border border-slate-300 p-4 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-blue-500 focus:outline-none dark:border-slate-700 dark:text-slate-200 dark:hover:bg-blue-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 5l0 14"></path>
              <path d="M5 12l14 0"></path>
            </svg>
            {t("sidebar.new_chat")}
          </button>
        </div>
        <div className=" mx-2">
          {isHistoryVisible && (
            <HistoryComponent onHistoryClick={handleHistoryClick} />
          )}
          {isPromptListVisible && (
            <PromptListComponent
              promptTemplates={promptTemplates}
              onPromptClick={handlePromptClick}
              onNewPromptClick={handleNewPromptClick}
            />
          )}
        </div>

        <div className="px-4 py-4 bottom-0  bg-white dark:bg-slate-900">
          <div className="w-full flex flex-col space-y-2">
            <div
              className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-gray-500 focus:outline-none dark:text-slate-200 dark:hover:bg-gray-600"
              onClick={toggleHistoryVisibility}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10"></path>
                <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2"></path>
              </svg>
              {t("sidebar.chat_history")}
              <div className="ml-auto">
                <button
                  className="flex items-center gap-x-2 rounded-lg px-2 py-1 text-sm font-medium text-white  hover:bg-blue-600 focus:outline-none"
                  onClick={handleNewChatClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 5v14m-7-7h14"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div
              className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-gray-500 focus:outline-none dark:text-slate-200 dark:hover:bg-gray-600"
              onClick={togglePromptListVisibility}
            >
              <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                className="h-6 w-6"
              >
                <path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zm-40 824H232V687h97.9c11.6 32.8 32 62.3 59.1 84.7 34.5 28.5 78.2 44.3 123 44.3s88.5-15.7 123-44.3c27.1-22.4 47.5-51.9 59.1-84.7H792v-63H643.6l-5.2 24.7C626.4 708.5 573.2 752 512 752s-114.4-43.5-126.5-103.3l-5.2-24.7H232V136h560v752zM320 341h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8zm0 160h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z" />
              </svg>
              {t("sidebar.prompt_template")}
              <div className="ml-auto">
                <button
                  className="flex items-center gap-x-2 rounded-lg px-2 py-1 text-sm font-medium text-white  hover:bg-blue-600 focus:outline-none"
                  onClick={handleNewPromptClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 5v14m-7-7h14"></path>
                  </svg>
                </button>
              </div>
            </div>
            {isPopupOpen && (
              <div className="absolute  left-1 bottom-4 mt-2 mr-4 w-48 bg-white border border-gray-200 dark:bg-gray-900 dark:border-gray-700 rounded-lg shadow-lg ">
                <div className="py-1">
                  <button
                    onClick={() => setIsSettingsModalOpen(true)} // Abre el UserSettingsModal al hacer clic en Profile
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 focus:outline-none  dark:hover:bg-blue-600 dark:hover:text-white"
                  >
                    {t("user_settings.title")}
                  </button>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 focus:outline-none  dark:hover:bg-blue-600 dark:hover:text-white"
                  >
                    {t("sidebar.logout")}
                  </button>
                </div>
              </div>
            )}
            <button
              className="flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200  focus:outline-none dark:text-slate-200 dark:hover:bg-gray-600"
              onClick={() => setIsPopupOpen(!isPopupOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"></path>
              </svg>

              {t("sidebar.user")}
              <div className="ml-auto">
                <button
                  className="flex items-center gap-x-2 rounded-lg px-2 py-1 text-sm font-medium text-white  hover:bg-blue-600 focus:outline-none"
                  onClick={() => setIsPopupOpen(!isPopupOpen)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 5v14m-7-7h14"></path>
                  </svg>
                </button>
              </div>
            </button>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default SidebarComponent;

class Constants {
  // Extensiones de archivos
  static readonly FILE_EXTENSIONS = {
    PDF: ["pdf"],
    OFFICE: [
      "doc",
      "docx",
      "dot",
      "dotx",
      "xls",
      "xlsx",
      "xlsm",
      "xltx",
      "xltm",
      "ppt",
      "pptx",
      "pptm",
      "pot",
      "potx",
    ],
    TEXT: ["txt", "rtf", "odt", "ott"],
    IMAGE: [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "svg",
      "webp",
      "tiff",
      "tif",
      "ico",
      "heic",
    ],
    AUDIO: ["mp3", "wav", "aac", "ogg", "flac", "m4a"],
    VIDEO: ["mp4", "mov", "avi", "mkv", "webm", "flv", "wmv"],
    COMPRESSED: ["zip", "rar", "7z", "tar", "gz", "bz2"],
    OTHER_DOCS: ["csv", "xml", "json", "html", "md"],
  };

  // Mensajes de error
  static readonly ERROR_MESSAGES = {
    FILE_NOT_SUPPORTED: "El tipo de archivo no es compatible.",
    UPLOAD_FAILED: "Error al cargar el archivo. Por favor, inténtelo de nuevo.",
    NETWORK_ERROR: "Error de red. Verifica tu conexión.",
  };

  // Rutas de la API
  static readonly API_ROUTES = {
    UPLOAD_FILE: "/api/upload",
    GET_FILES: "/api/files",
    DELETE_FILE: "/api/delete",
  };

  // Otros valores de configuración
  static readonly CONFIG = {
    MAX_FILE_SIZE_MB: 50,
    DEFAULT_LANGUAGE: "es",
    SUPPORTED_LANGUAGES: ["es", "en", "fr"],
  };

  static isAllowedFile(filename: string): boolean {
    const extension = filename.split(".").pop();

    return Object.values(Constants.FILE_EXTENSIONS).some(
      (allowedExtensions) => extension && allowedExtensions.includes(extension)
    );
  }
}
export default Constants;

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import SidebarComponent from "./component/SidebarComponent";
import { PromptTemplate } from "./Entity/PromptTemplate";
import { ChatMessage } from "./Entity/ChatMessage";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  CredentialResponse,
} from "@react-oauth/google";
import ENDPOINTS from "./config";
import "./i18n";
import ChatComponent from "./component/ChatComponents/ChatComponent";
import RegisterComponent from "./component/LoginComponents/RegisterComponent";
import LoginComponent from "./component/LoginComponents/LoginComponent";

const GOOGLE_CLIENT_ID =
  "623206273873-e3ik0q3vhnlkgugp5vo36k22led4990k.apps.googleusercontent.com";

function App() {
  const [promptTemplates, setPromptTemplates] = useState<PromptTemplate[]>([]);
  const [historyId, setHistoryId] = useState<number | null>(null); // Nuevo estado para almacenar el historyId
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [user, setUser] = useState<any>(null);

  const updatePromptTemplates = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found");
      }
      const response = await fetch(ENDPOINTS.PROMPT_TEMPLATE(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const templates = data.map((item: any) => ({
        id: item.id,
        name: item.name,
        content: item.content,
        description: item.description,
      }));
      setPromptTemplates(templates);
    } catch (error) {
      console.error("Error fetching prompt templates:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Lógica para verificar el token y obtener la información del usuario
      const fetchUserData = async () => {
        try {
          const response = await fetch(ENDPOINTS.AUTH_ME, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const userData = await response.json();
            setUser(userData);
          } else {
            localStorage.removeItem("token");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          localStorage.removeItem("token");
        }
      };
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    if (user) {
      updatePromptTemplates();
    }
  }, [user]);

  const handleSavePromptTemplate = (newTemplate: PromptTemplate) => {
    setPromptTemplates((prevTemplates) => [...prevTemplates, newTemplate]);
    const token = localStorage.getItem("token");
    fetch(ENDPOINTS.PROMPT_TEMPLATE(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newTemplate),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateMessages = (newMessages: any) => {
    console.log("Actualizando mensajes: ", newMessages);
    if (newMessages.length === 0) {
      setMessages([]);  
      setHistoryId(null);
      return;
    }
  
    setMessages((prevMessages: any[]) => {
      const updatedMessages = newMessages.map(
        (msg: {
          id: number;
          sender: string;
          message: any;
          evaluation: any;
          images: any[];
          reference: any[];
        }) => ({
          sender: msg.sender === "user" ? "user" : "assistant",
          message: msg.message,
          id: msg.id,
          evaluation: msg.evaluation,
          images: msg.images,
          reference: msg.reference,
        })
      );
  
      // Actualizamos o agregamos cada mensaje basado en su ID
      const mergedMessages = prevMessages.map((prevMsg) => {
        const newMsg = updatedMessages.find((msg: { id: any; }) => msg.id === prevMsg.id);
        return newMsg ? newMsg : prevMsg;
      });
  
      // Agregar mensajes nuevos que no existían en prevMessages
      const newUniqueMessages = updatedMessages.filter(
        (msg: { id: any; }) => !prevMessages.some((prevMsg) => prevMsg.id === msg.id)
      );
  
      return [...mergedMessages, ...newUniqueMessages];
    });
  
    // Actualizar el chat_history_id si está presente
    if (newMessages.length > 0 && newMessages[0].chat_history_id) {
      setHistoryId(newMessages[0].chat_history_id);
    }
  };
  

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Router>
        <div className="flex flex-row h-screen overflow-hidden bg-slate-300 dark:bg-slate-800 text-slate-900 dark:text-slate-300 sm:text-base sm:leading-7 dark">
          {user ? (
            <>
              <SidebarComponent
                onSave={handleSavePromptTemplate}
                updatePromptTemplates={updatePromptTemplates}
                updateMessages={updateMessages}
                setHistoryId={setHistoryId}
                promptTemplates={promptTemplates}
              />
              <Routes>
                <Route path="*" element={<Navigate to="/" />} />
                <Route
                  path="/"
                  element={
                    <ChatComponent
                      promptTemplates={promptTemplates}
                      setPromptTemplates={setPromptTemplates}
                      messages={messages}
                      updateMessages={updateMessages}
                      historyId={historyId}
                    />
                  }
                />
              </Routes>
            </>
          ) : (
            <Routes>
              <Route
                path="/login"
                element={<LoginComponent setUser={setUser} />}
              />
              <Route path="/register" element={<RegisterComponent />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;

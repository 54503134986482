import React, { useState, useRef, useEffect, useCallback } from "react";
import PromptMessageComponent from "./PromptMessageComponent";
import PromptInputComponent from "./PromptInputComponent";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { PromptTemplate } from "../../Entity/PromptTemplate";
import { ChatMessage } from "../../Entity/ChatMessage";
import ENDPOINTS from "../../config";
import CardList from "../DocumentComponents/CardList";
import { styled } from "@mui/system";
import { DocumentRAG } from "../../Entity/DocumentRAG";
import { Switch } from "@mui/material";

const CustomPanelResizeHandle = styled(PanelResizeHandle)(({ theme }) => ({
  width: "10px", // Anchura de la manija de arrastre
  backgroundColor: "#080b13",
  cursor: "col-resize",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  transition: "background-color 0.3s ease, width 0.3s ease",
  "&:hover": {
    backgroundColor: "#080b13",
    width: "12px",
  },
  "&::before": {
    content: '""',
    width: "2px",
    height: "50%",
    backgroundColor: "#333",
    borderRadius: "2px",
    transition: "height 0.3s ease",
  },
  "&:hover::before": {
    height: "80%",
  },
}));

interface ChatComponentProps {
  promptTemplates: PromptTemplate[];
  setPromptTemplates: React.Dispatch<React.SetStateAction<PromptTemplate[]>>;
  messages: ChatMessage[];
  updateMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  historyId: number | null;
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  promptTemplates,
  setPromptTemplates,
  messages,
  updateMessages,
  historyId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);
  const logoUrl = process.env.REACT_APP_LOGO_URL || "";
  const nameOfCompany = process.env.REACT_APP_COMPANY_NAME || "";
  const [documents, setDocuments] = useState<DocumentRAG[]>([]);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [modelSelect, setModelSelect] = useState("gpt-4o");
  const [internetAccess, setInternetAccess] = useState(true);
  const [documentAccess, setDocumentAccess] = useState(true);
  const [selectedDocumentName, setSelectedDocumentName] = useState<string | null>(null);

  useEffect(() => {
    const handleResize = () => {
      const isCurrentResponsive = window.innerWidth <= 768;
      setIsResponsive(isCurrentResponsive);
      // Si estamos en modo escritorio, asegúrate de que el panel esté visible
      if (!isCurrentResponsive) {
        setIsPanelVisible(true);
      }
    };

    window.addEventListener("resize", handleResize);
    // Ejecutar la lógica de cambio de tamaño inmediatamente para el estado inicial
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isResponsive) {
      setIsPanelVisible(false);
    }
  }, [isResponsive]);

  useEffect(() => {
    if (historyId !== null) {
      console.log(`Current historyId: ${historyId}`);
    }
  }, [historyId]);

  useEffect(() => { }, [messages, updateMessages]);
  useEffect(() => {
    fetchDocuments(page, searchTerm);
  }, [page, searchTerm]);



  const handleModelChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setModelSelect(e.target.value);
  };


  const isBase64Image = (str: string) => {
    const regex = /^[A-Za-z0-9+/]+[=]{0,2}$/;
    return regex.test(str);
  };
  // Función para manejar el envío de un nuevo mensaje
  const handleSendMessage = async (
    newMessage: string,
    selectedFile: any,
    configuration: any
  ) => {
    try {
      setIsLoading(true);
      configuration.model = modelSelect;
      configuration.internet_access = internetAccess;
      configuration.document_access = documentAccess;

      const token = localStorage.getItem("token");
      const formData = new FormData();

      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      formData.append(
        "chat_message",
        JSON.stringify({ message: newMessage, sender: "user", id: 0 })
      );
      formData.append("chat_history_id", JSON.stringify(historyId || 0));
      formData.append("chat_history_message", JSON.stringify(messages));
      formData.append("configuration", JSON.stringify(configuration));

      const response = await fetch(ENDPOINTS.ASK_QUESTION, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Create a reader to read the stream
      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let accumulatedMessage = "";
      let chat_history_id = 0;
      let assistant_message_id = 0;
      let images: { src: string, source: string }[] = [];
      let reference: string[] = [];

      // Create the assistant message
      let assistantMessage: ChatMessage = {
        id: -1,
        sender: "assistant",
        message: "",
        images: [],
      };


      // Process the stream
      while (reader && true) {
        const { value, done } = await reader.read();

        if (done) {
          console.log("Finalizacion");
          break;
        }

        // Decode the chunk and accumulate it
        const chunk = decoder.decode(value);
        console.log("Chunk ...... ", chunk);

        //si el chunk contienen { y } es un json y se debe descomponer
        if (chunk.includes("{") && chunk.includes("}")) {
          //si el chunk tiene esta estructura [{"user_message_id": 248, "chat_history_id": 132, "assistant_message_id": 249}] descomponer
          //y actualizar el chat_history_id
          try {
            const data = JSON.parse(chunk);
            if (data[0].chat_history_id) {
              chat_history_id = data[0].chat_history_id;
              assistant_message_id = data[0].assistant_message_id;
              console.log("Actualizando mensaje", data);

              updateMessages([
                ...messages,
                {
                  id: data[0].user_message_id,
                  sender: "user",
                  message: newMessage,
                  chat_history_id: data[0].chat_history_id
                },
              ]);
              if (lastMessageRef.current) {
                lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
              }
             
            }
          } catch (error) {
            console.log("Error al convertir el contexto en imagenes:", error);
          }

          //si el chunk tiene esta estructura[    {      "source": "",      "page_content": "" , "doc_id": 0  },
          try {
            const context = JSON.parse(chunk);
            if (context[0].source) {
               console.log("Contexto", context);


              let fileNameSet = new Set();
              try {
                for (let i = 0; i < context.length; i++) {
                  try {
                    if (isBase64Image(context[i].page_content)) {
                      const imageSource = context[i].source;
                      const fileName = imageSource.split('\\').pop().split('/').pop();
                      images.push({ src: context[i].page_content, source: fileName });
                    }
                    else {
                      const pathFile = context[i].source
                      //si el pathfile es una URL dejarla asi 
                      if (pathFile.startsWith('http')) {
                        reference.push(pathFile);
                        continue;
                      }

                      const fileName = pathFile.split('\\').pop().split('/').pop();
                      if (!fileNameSet.has(fileName)) {
                        fileNameSet.add(fileName);
                        reference.push(fileName);
                      }
                    }
                  } catch (error) {
                    console.error("Error al convertir el contexto en imagenes:", error);
                  }
                }
              } catch (error) {
                console.error("Error al convertir el contexto en imagenes:", error);
              }
            }
          } catch (error) {
            console.log("Error a procesar el contexto:", error);
          }
          continue;
        }
        console.log("Acumulando mensaje");
        setIsLoading(false);
        accumulatedMessage += chunk;
        assistantMessage.id = assistant_message_id;
        assistantMessage.chat_history_id = chat_history_id;
        assistantMessage.message = accumulatedMessage;
        assistantMessage.images = images;
        assistantMessage.reference = reference;
        updateMessages([...messages, assistantMessage]);

      }

    } catch (error) {
      console.error("Error sending message:", error);
    } finally {

    }
  };

  const togglePanelVisibility = () => {
    setIsPanelVisible((prev) => !prev);
  };

  const handleDocumentSelect = (documentName: string) => {
    setSelectedDocumentName(documentName);
  };

  const fetchDocuments = async (page: number, search: string) => {
    setIsDocumentsLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${ENDPOINTS.DOCUMENT_RAG}?page=${page}&search=${search}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    const data: DocumentRAG[] = await response.json();
    if (data.length === 0) {
      setHasMoreData(false);
    }
    if (page === 1) {
      setDocuments(data);
    } else {
      setDocuments(prevDocuments => [...prevDocuments, ...data]);
    }
    setIsDocumentsLoading(false);
  };

  const handleScroll = () => {
    if (hasMoreData && !isDocumentsLoading) {
      setPage(prevPage => prevPage + 1);
    }
  };


  useEffect(() => {
    const interval = setInterval(() => {
      if (hasMoreData && !isDocumentsLoading) {
        setPage(prevPage => prevPage + 1);
      }
    }, 40000);

    return () => clearInterval(interval);
  }, [hasMoreData, isDocumentsLoading]);


  const switchDocumentAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentAccess(event.target.checked);
  };

  const switchInternetAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternetAccess(event.target.checked);
  };

  return (
    <PanelGroup direction="horizontal"
      style={{ height: "100vh" }} >
      <Panel defaultSize={50} minSize={20} maxSize={80}>
        <header className="bg-slate-600/80 backdrop-blur-md text-white flex justify-between items-center  z-10 h-8 px-4">
          <div className="flex justify-center">
            <select
              id="modelSelect"
              className="bg-slate-700 text-white text-sm h-8 px-2 py-1 rounded-md focus:outline-none"
              value={modelSelect}
              onChange={handleModelChange}
            >
              <option value="gpt-4o"> GPT-4o </option>
              <option value="o1-preview"> GPT-o1-preview </option>
              <option value="gpt-4o-mini"> GPT-4o mini </option>
              <option value="o1-mini"> GPT-o1-mini </option>
            </select>
          </div>

          <div className="flex items-center">
            <label className="text-sm font-medium">Internet Search:</label>
            <Switch
              color="primary"
              onChange={switchInternetAccess}
              checked={internetAccess}
            />
          </div>
          <div className="flex items-center">
            <label className="text-sm font-medium">Documents Search:</label>
            <Switch
              color="primary"
              onChange={switchDocumentAccess}
              checked={documentAccess}
            />
          </div>
          <div
            className="handle relative"
            style={{ transition: "transform 0.2s ease" }}
          >
            <button
              onClick={togglePanelVisibility}
              className="focus:outline-none p-2 hover:bg-slate-700 rounded-full transition duration-200 ease-in-out"
            >
              {isPanelVisible ? (
                <div></div>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              )}
            </button>
          </div>
        </header>
        <div
          className={`flex ${isResponsive ? "w-full" : "w-auto"
            } h-[calc(100vh-26px)] flex-col rounded-lg  `}
        >
          <PromptMessageComponent
            messages={messages}
            lastMessageRef={lastMessageRef}
            isLoading={isLoading}
            onSelectDocument={handleDocumentSelect}
          />
          <PromptInputComponent
            onSendMessage={handleSendMessage}
            promptTemplates={promptTemplates}
            setPromptTemplates={setPromptTemplates}
            isLoading={isLoading}
          />
        </div>
      </Panel>
      {isPanelVisible ? (
        <CustomPanelResizeHandle></CustomPanelResizeHandle>
      ) : (
        <CustomPanelResizeHandle style={{ display: "none" }} />
      )}

      {isPanelVisible && (
        <Panel defaultSize={50} minSize={20} maxSize={80}>
          <header className="bg-slate-600/80 backdrop-blur-md text-white flex justify-between items-center z-10 h-8 px-4">
            <div
              className="handle relative ml-auto"
              style={{ transition: "transform 0.2s ease" }}
            >
              <button
                onClick={togglePanelVisibility}
               className="focus:outline-none p-2 hover:bg-slate-700 rounded-full transition duration-200 ease-in-out"
              >
                {isPanelVisible ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                )}
              </button>
            </div>
          </header>
          <div className="flex flex-col h-[calc(100vh-36px)] w-full max-w-screen-lg mx-auto rounded-lg   overflow-hidden">
            <CardList
              documents={documents}
              fetchDocuments={fetchDocuments}
              handleScroll={handleScroll}
              isDocumentsLoading={isDocumentsLoading}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedDocumentName={selectedDocumentName}
            />
          </div>
        </Panel>
      )}
    </PanelGroup>
  );
};

export default ChatComponent;
